<template>
  <div>
    <b-button-group>
      <b-button variant="success" @click="newRequisition">New Requisition</b-button>
      <b-button variant="primary" @click="showFilter()">Search Requisitions</b-button>
    </b-button-group>
    <div v-show="filter_show">
      <b-row no-gutters>
        <b-col cols="12">
          <b-form-input v-model="filter.text" placeholder="Contains text...">
          </b-form-input>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-select v-model="filter.status" :options="['pending', 'submitted', 'received']">
            <template #first>
              <b-form-select-option :value="null">-- Status --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-select v-model="filter.vendor" :options="vendors">
            <template #first>
              <b-form-select-option :value="null">-- Select Vendor --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-input list="co-list-id" v-model="filter.cost_object" placeholder="Select Cost Object"></b-form-input>
          <datalist id="co-list-id">
            <option v-for="co in cost_objects" :key="co.id">{{co.id}} ({{co.description}})</option>
          </datalist>
        </b-col>
        <b-col cols="12" md="6">
          <b-input-group>
            <b-form-datepicker v-model="filter.start_date" reset-button placeholder="Submitted after"></b-form-datepicker>
            <b-form-datepicker v-model="filter.end_date" reset-button placeholder="Submitted before"></b-form-datepicker>
          </b-input-group>
        </b-col>
      </b-row>
        <b-row>
          <b-col>
            <div class="text-right">
              <b-button squared variant="primary" v-on:click="applyFilter">Apply</b-button>
              <b-button squared variant="danger" v-on:click="hideFilter">Close Filter</b-button>
            </div>
          </b-col>
        </b-row>
    </div>
    <hr/>
    <b-row>
      <b-col cols="12" sm="6" md="4" lg="4" v-for="req in requisitions" :key="req.id">
        <b-card :border-variant="reqToClass(req)"
                no-body>
          <a :href="'/requisition/'+req.id" style="color:black">
            <b-card-header class="req-header" :header-bg-variant="reqToClass(req)">
              {{req.title || 'No Title'}}
            </b-card-header>
          </a>
          <b-card-body style="padding: 1px">
            <table class="table table-striped" >
              <tbody>
              <tr> <td>Vendor:</td> <td style="text-align: right">{{req.vendor.name || "—"}}</td> </tr>
              <tr> <td>Submitter:</td> <td style="text-align: right">{{req.extra.submitter_username || "—"}}</td> </tr>
              <tr> <td>Cost Object:</td> <td style="text-align: right">{{req.extra.cost_object || "—"}}</td> </tr>
              <tr> <td>Grand Total:</td> <td style="text-align: right">
                ${{req.grand_total.toLocaleString('en-US',{ minimumFractionDigits: 2})}}
              </td> </tr>
              <tr> <td>Status:</td> <td style="text-align: right"><b>{{req.status}}</b>{{statusAnnotation(req)}}</td> </tr>
              </tbody>
            </table>
            <b-button-group style="text-align: right; width: 100%">
              <b-button variant="primary" @click="viewRequisition(req)" style="float: none; width: 25%"
                        title="View Requisition Details">
                <b-icon icon="eye-fill"></b-icon>
              </b-button>
              <b-button variant="success" @click="downloadRequisition(req)" style="float: none; width: 25%"
                        title="Download Requisition PDF">
                <b-icon icon="download" ></b-icon>
              </b-button>
              <b-button variant="warning" @click="editRequisition(req)" style="float: none; width: 25%"
                        title="Edit Requisition">
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button variant="danger" v-b-modal="'delete-confirmation-'+req.id" style="float: none; width: 25%"
                        title="Delete Requisition">
                  <b-icon icon="trash"></b-icon>
              </b-button>
              <b-modal :id="'delete-confirmation-'+req.id" ok-title="Delete" ok-variant='danger'
                       title="Delete Confirmation" @ok="deleteRequisition(req)">
                <p class="my-4">Confirm deletion of Requisition #{{req.id}} "{{req.title || 'No Title'}}"</p>
              </b-modal>
            </b-button-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-pagination
        v-model="current_page"
        :total-rows="n_reqs"
        :per-page="per_page"
        align="center"
        v-on:change="loadRequisitions"
        first-number last-number
    >
    </b-pagination>
  </div>

</template>

<script>
import http from "../http-common"
import FileDownload from "js-file-download"
import contentDisposition from "../content-disposition-parser"

export default {
  name: "RequisitionHome",
  props: [
      'cost_objects',
      'loggedIn',
  ],
  data: function () {
    return {
      requisitions: [],
      filter_show: false,
      n_reqs: 0,
      current_page: 1,
      per_page: 12,
      filter: {
        cost_object: null,
        vendor: null,
        start_date: null,
        end_date: null,
        status: null,
      },
      vendors: [],
    };
  },
  methods: {
    reqToClass(req) {
      let cls = "warning";
      if (req.date_submitted !== null) {
        cls = "primary";
      }
      if (req.date_received !== null) {
        cls = "success";
      }
      return cls;
    },
    statusAnnotation(req) {
      let ann = "";
      if (req.date_submitted !== null) {
        ann = `@${req.date_submitted}`;
      }
      if (req.date_received !== null) {
        ann = `@${req.date_received}`;
      }
      return ann;
    },
    showFilter() {
      this.$emit('sync-cost-objects');
      this.filter_show = true;
    },
    hideFilter() {
      this.filter_show = false;
      this.filter.text = null;
      this.filter.cost_object = null;
      this.filter.vendor = null;
      this.filter.start_date = null;
      this.filter.end_date = null;
      this.filter.status = null;
      this.loadRequisitions();
    },
    applyFilter() {
      this.loadRequisitions();
    },
    viewRequisition(requisition) {
      this.$router.push('/requisition/'+requisition.id);
    },
    editRequisition(requisition) {
      this.$router.push('/edit-requisition/'+requisition.id);
    },
    deleteRequisition(requisition) {
      http.delete('/requisition/'+requisition.id)
      .then(() => {
        this.loadRequisitions();
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
    },
    newRequisition() {
      http.post("/requisition/")
      .then((resp) => {
        this.$router.push('/edit-requisition/'+resp.data.id)
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
    },
    downloadRequisition(req) {
      let loader = this.$loading.show()
      http.get("/requisition/download/"+req.id,
          {responseType: 'blob'})
      .then((resp) => {
        let cd = contentDisposition.parse(resp.request.getResponseHeader('Content-Disposition'));
        FileDownload(resp.data, cd.parameters.filename);
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      .finally(() => {
        loader.hide()
      })
    },
    loadRequisitions(page) {
      let loader = this.$loading.show();
      if ((page||null) === null) {
        this.current_page = 1;
      } else {
        this.current_page = page;
      }
      http.get("/requisition/",{
        params: {
          offset: (this.current_page - 1) * this.per_page,
          limit: this.per_page,
          req_status: this.filter.status,
          vendor: this.filter.vendor,
          cost_object: this.filter.cost_object,
          start_date: this.filter.start_date === "" ? null : this.filter.start_date,
          end_date: this.filter.end_date === "" ? null : this.filter.end_date,
          text: this.filter.text,
        }
      })
      .then((resp) => {
        this.requisitions = resp.data.requisitions;
        this.n_reqs = resp.data.n_requisitions;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      .finally(() => {
        loader.hide();
      });
    },
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
  },
  mounted() {
    this.loadRequisitions();
    http.get("/vendor/")
        .then((resp) => {
          this.vendors = [];
          resp.data.forEach((obj) => {
            this.vendors.push(obj.name);
          });
        })
        .catch((e) => {
          http.std_error_handler(this, e);
        });
    },
  }
</script>

<style scoped>

</style>

<style>
.req-header {
  font-weight: bolder;
  text-align: center;
}
</style>